<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <span v-on="on">
        <v-btn
          icon
          color="primary"
          x-small
          v-bind="{ ...attrs, ...$attrs }"
          target="_blank"
          :href="targetLink"
          @click.stop
        >
          <ESvgIcon
            v-if="svgIcon"
            :color="$vuetify.theme.themes.light.primary"
            :icon="svgIcon"
            v-bind="iconProps"
          />
          <v-icon v-else small v-bind="iconProps"> {{ icon }} </v-icon>
        </v-btn>
      </span>
    </template>
    <span>{{ hint }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "OpenInNewWindowButton",
  props: {
    hint: {
      type: String,
      default: "Open Account In New Window",
    },
    targetLink: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: "fa-external-link-alt",
    },
    svgIcon: {
      type: String,
      default: "",
    },
    iconProps: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
