<template>
  <div class="display-inline-table">
    <v-tooltip bottom :disabled="disabledTooltip">
      <template #activator="{ on }">
        <span v-on="on">
          <slot name="custom-button" v-bind="{ onClick: handleClick }">
            <v-btn icon color="primary" x-small @click="handleClick">
              <v-icon small> {{ icon }} </v-icon>
            </v-btn>
          </slot>
        </span>
      </template>
      <span> {{ tooltip }} </span>
    </v-tooltip>
  </div>
</template>

<script>
import { AdminApi } from "@evercam/shared/api/adminApi"

export default {
  name: "ImpersonateUserButton",
  props: {
    email: {
      type: String,
      default: null,
    },
    targetRoute: {
      type: String,
      default: "/projects",
    },
    ndaProject: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "fa-external-link-alt",
    },
    tooltip: {
      type: String,
      default: () => "Open Account In New Window",
    },
    disabledTooltip: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      if (this.ndaProject) {
        this.openNdaImpersonationDialog()
      } else {
        this.impersonateUser(this.email)
      }
    },
    async openNdaImpersonationDialog() {
      if (
        await this.$confirmDialog.open({
          title: "NDA/MC",
          message:
            "This Project/Camera is under <span class='font-weight-bold'>Non Disclosure Agreement / Mission Critical (NDA/MC)</span> tag. Are you sure you want to impersonate?",
        })
      ) {
        this.impersonateUser(this.email)
      }
    },
    getImpersonationDashUrl() {
      return this.$config.public.appContext === "preview"
        ? this.$config.public.previewDashUrl || this.$config.public.dashURL
        : this.$config.public.dashURL
    },
    async impersonateUser(email) {
      if (!email) {
        this.$notifications.error({ text: "Account email is not specified!" })

        return
      }
      try {
        const { token } = await AdminApi.users.impersonateUser(email)
        if (this.targetRoute.includes("demo")) {
          window.open(this.targetRoute, "_blank")
        } else {
          const dashUrl = this.getImpersonationDashUrl()
          const querySeparator = this.targetRoute.includes("?") ? "&" : "?"
          window.open(
            `${dashUrl}${this.targetRoute}${querySeparator}impersonation_token=${token}`,
            "_blank"
          )
        }
      } catch (error) {
        this.$notifications.error({
          text: "User Impersonation failed!",
          error,
        })
      }
    },
  },
}
</script>

<style scope>
.display-inline-table {
  display: inline-table;
}
</style>
